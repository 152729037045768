

































































































































import {defineComponent, PropType, provide, Ref, ref } from '@nuxtjs/composition-api';
import {
  SfButton, SfSelect, SfDropdown, SfRadio
} from '~/components';
import SvgImage from '~/components/General/SvgImage.vue';
import { Aggregation, useUiHelpers, useUiState } from '~/composables';
import { Pagination } from '~/composables/types';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import {
  SelectedFiltersInterface,
  useFilters,
} from '~/modules/catalog/category/components/filters/useFilters';
import { getFilterConfig, isFilterEnabled } from '~/modules/catalog/category/config/FiltersConfig';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';

export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>,
  filters: Ref<Aggregation[]>,
}

export default defineComponent({
  components: {
    CategoryPagination,
    SkeletonLoader,
    SvgImage,
    SfSelect,
    SfButton,
    SfDropdown,
    SfRadio,
    CheckboxType: () => import('~/modules/catalog/category/components/filters/renderer/CheckboxType.vue'),
    SwatchColorType: () => import('~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue'),
    RadioType: () => import('~/modules/catalog/category/components/filters/renderer/RadioType.vue'),
    YesNoType: () => import('~/modules/catalog/category/components/filters/renderer/YesNoType.vue'),
    RangeType: () => import('~/modules/catalog/category/components/filters/renderer/RangeType.vue'),
  },
  props: {
    sortBy: {
      type: Object as PropType<SortingModel>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hotFiltersQty: {
      type: Number,
      default: 3,
    },
  },
  setup(props, { emit }) {
    const {
      toggleFilterSidebar, changeToCategoryListView, changeToCategoryGridView, isCategoryGridView,
    } = useUiState();
    const uiHelpers = useUiHelpers();
    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      emit('reloadProducts');
    };

    const toggleCategoryView = () => isCategoryGridView.value
      ? changeToCategoryListView()
      : changeToCategoryGridView();

    const filters = ref<Aggregation[]>([]);
    const removableFilters = ref([]);

    const {
      selectedFilters, selectFilter, isFilterSelected,
    } = useFilters();


    provide('UseFiltersProvider', { isFilterSelected, selectedFilters, filters });

    const handleFilters = (filtersData) => {
      filters.value = filtersData[0].value.slice(0, props.hotFiltersQty);
      selectedFilters.value = filtersData[1].value;
      removableFilters.value = filtersData[2].value;
    }

    let childInterface;
    const getChildInterface = (childInterfaceFromChild) => {
      childInterface = childInterfaceFromChild;
    };

    const closeSortDropdown = () => {
      childInterface.handler();
    };

    return {
      getChildInterface,
      closeSortDropdown,
      handleFilters,
      toggleFilterSidebar,
      toggleCategoryView,
      doChangeSorting,
      ...uiHelpers,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      selectFilter,
      getFilterConfig,
      filters,
      selectedFilters,
      removableFilters,
    };
  },
});
