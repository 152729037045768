



















































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfHeading,
  SfButton,
  SfSelect,
} from '~/components';
import {
  defineComponent,
  onMounted, onUpdated,
  ref,
  ssrRef,
  useContext,
  useFetch,
  useRouter,
} from '@nuxtjs/composition-api';
import { usePageStore } from '~/stores/page';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';
import {
  Products,
  useFacet,
  useProduct,
  useUiHelpers,
  useUiState,
} from '~/composables';

import { useAddToCart } from '~/helpers/cart/addToCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { usePrice } from '~/modules/catalog/pricing/usePrice';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';

import SearchNavbar from '~/modules/catalog/category/components/navbar/SearchNavbar.vue';

import type { ProductInterface } from '~/modules/GraphQL/types';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { Pagination } from '~/composables/types';
import type { Product } from '~/modules/catalog/product/types';
import RunWhenVisible from '~/components/utils/RunWhenVisible.vue';

export default defineComponent({
  name: 'Search',
  components: {
    RunWhenVisible,
    CategoryPagination,
    SearchEmptyResults: () => import('~/modules/catalog/category/components/SearchEmptyResults.vue'),
    CategoryFilters: () => import('~/modules/catalog/category/components/filters/CategoryFilters.vue'),
    CategoryProductGrid: () => import('~/modules/catalog/category/components/views/CategoryProductGrid.vue'),
    CategoryProductList: () => import('~/modules/catalog/category/components/views/CategoryProductList.vue'),
    SearchNavbar,
    SfButton,
    SfSelect,
    LazyHydrate,
    SfHeading,
    SkeletonLoader,
  },
  transition: 'fade',
  setup() {
    const router = useRouter();
    const { localeRoute } = useContext();
    const { routeData } = usePageStore();
    const uiHelpers = useUiHelpers();

    const products = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: '', options: [] });
    const pagination = ref<Pagination>({});
    const productContainerElement = ref<HTMLElement | null>(null);

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
    } = useUiState();
    const {
      load: loadWishlist,
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();
    const { addItemToCart } = useAddToCart();
    const { result, search, loading } = useFacet();


    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
    };


    const searchTerm = ref(uiHelpers.getSearchTermFromUrl().term);

    const { fetch } = useFetch(async () => {
      const [content] = await Promise.all([
        search({ ...uiHelpers.getFacetsFromURL() }),
      ]);

      products.value = facetGetters.getProducts(result.value) ?? [];

      sortBy.value = facetGetters.getSortOptions(result.value);
      pagination.value = facetGetters.getPagination(result.value);
    });

    const isPriceLoaded = ref(false);

    onMounted(async () => {
      loadWishlist();
      const { getPricesBySku } = usePrice();
      if (products.value.length > 0) {
        const skus = products.value.map((item) => item.sku);
        const priceData = await getPricesBySku(skus, pagination.value.itemsPerPage);
        products.value = products.value.map((product) => {
          const priceRange = priceData.items.find((item) => item.sku === product.sku)?.price_range;

          if (priceRange) {
            return {
              ...product,
              price_range: priceRange,
            };
          }

          return { ...product };
        });
      }

      isPriceLoaded.value = true;
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
    };

    const goToHome = () => router.push(localeRoute({ name: 'home' }));

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      routeData,
      doChangeItemsPerPage,
      productContainerElement,
      onReloadProducts,
      goToPage,
      searchTerm,
      goToHome,
      loading,
    };
  },
  head() {
    return Object.assign({},
      { 'title': `Search - ${this.searchTerm}` });
  },
});
