





import {
  defineComponent, nextTick, onMounted, onUnmounted, ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RunWhenVisible',
  props: {
    observerOptions: {
      type: Object,
      default: () => ({
        rootMargin: '0px 0px 0px 0px',
        threshold: 0.5
      }),
    },
    observerData: {
      type: Number,
      default: ''
    }
  },
  emits: ['intersectionObserver'],
  setup( props, { emit }) {
    const isVisible = ref(false);
    const observerRoot = ref(null);

    let observer = null;
      if (process.browser && ('IntersectionObserver' in window)) {
        observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if(entry.isIntersecting ) {
              emit('intersectionObserver', props.observerData);
            }
          });

        }, props.observerOptions);
      }

    onMounted(() => {
      nextTick(() => {
        if (observer) {
          observer.observe(observerRoot.value);
        }
      })
    });

    onUnmounted(() => {
      if (observer) {
        observer.unobserve(observerRoot.value);
      }
    });

    return {
      observerRoot,
      isVisible,
    };
  },
});
